import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import {
    salesGuestValidOrderResultVar,
} from '../layouts/guest-layout/guest-layout-state';
import { SalesLayout } from '../layouts/sales-layout';
import { TrackingContent } from './tracking-content';
import styles from './style.css';

const CreateReturnsGuestPage: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const salesGuestValidOrderResult = useReactiveVar(salesGuestValidOrderResultVar);

    const trackingComponent: JSX.Element = isLoaded ? (<TrackingContent />) : (<></>);

    useEffect(() => {
        if (!salesGuestValidOrderResult) {
            return;
        }

        const { result, redirectUrl } = salesGuestValidOrderResult;
        const isRedirect = !result && !!redirectUrl.length;

        if (isRedirect) {
            navigateToUrl({
                navigate,
                url: redirectUrl,
            });
            return;
        }

        setIsLoaded(true);
    }, [salesGuestValidOrderResult]);

    return (
        <SalesLayout
            pageKey="guest-tracking"
            pageTitle={t('Tracking')}
            pageContent={trackingComponent}
            salesLayoutClassName={styles.trackingPageLayout}
            showLeftSidebar={false}
            isGuestPage
            loadCart
            loadCountries
        />
    );
};

export { CreateReturnsGuestPage as default };
